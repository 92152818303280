import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ANGULAR MATERIAL COMPONENTS
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

/* COMPONENTS */
import { IntlTelInpComponent } from './components/intl-tel-inp/intl-tel-inp.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SearchOrganizationComponent } from './components/search-organization/search-organization.component';
import { EmailSettingComponent } from './components/email-setting/email-setting.component';
import { FourDigitPipe } from 'app/pipes/four-digit.pipe';
import { TransactionComponent } from './components/transaction/transaction.component';
import { MatRadioModule } from '@angular/material/radio';
import { SummaryGraphComponent } from './components/summary-graph/summary-graph.component';
import { PayoutTimelineComponent } from './components/payout-timeline/payout-timeline.component';
import { ConnectionServiceModule } from 'ng-connection-service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';



@NgModule({

    declarations: [
        IntlTelInpComponent,
        ImagePreviewComponent,
        SearchOrganizationComponent,
        EmailSettingComponent,
        TransactionComponent,
        FourDigitPipe,
        SummaryGraphComponent,
        PayoutTimelineComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        NgApexchartsModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        MatRadioModule,
        MatSelectModule,
        MatMenuModule,
        ConnectionServiceModule,
        MatButtonToggleModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatMenuModule,
        MatPaginatorModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatTabsModule,
        DragDropModule,
        MatAutocompleteModule,
        IntlTelInpComponent,
        ImagePreviewComponent,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatProgressBarModule,
        NgApexchartsModule,
        SearchOrganizationComponent,
        EmailSettingComponent,
        TransactionComponent,
        FourDigitPipe,
        MatSnackBarModule,
        MatRadioModule,
        SummaryGraphComponent,
        PayoutTimelineComponent,
        ConnectionServiceModule,
        MatButtonToggleModule,
        MatSlideToggleModule
    ]
})
export class SharedModule {
}
