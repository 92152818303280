import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Transactions } from 'app/interfaces/transaction';
import { User } from 'app/interfaces/users';
import { UserService } from 'app/core/user/user.service';
import { OrganizationService } from 'app/services/organization.service';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { environment } from 'environments/environment';
import { ToastService } from 'app/services/toast.service';
import { ExportExcelService } from 'app/services/export-excel.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Get Organization From Parent */
  private _organization: string;
  @Input('organization') set organization(organizationId: string) {
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {
        status: null,
        mint_status: null,
        page_limit: null,
        offset: null,
      },
      queryParamsHandling: 'merge'
    });
    if (organizationId) {
      this._organization = organizationId;
      this.page_index = 0;
      this.offset = 0;
      if (this.getUser.role == 'admin') {
        this.getTransactions();
      }
    } else {
      this._organization = '';
      this._collection = '';
      this.page_limit = 10;
      this.total_records = 0;
      this.page_index = 0;
      this.offset = 0;
      this.transactions = [];
    }
  };
  get organization(): string {
    return this._organization;
  }

  /** Get Collection From Parent */
  private _collection: string;
  @Input('collection') set collection(collectionId: string) {
    setTimeout(() => {
      this._collection = collectionId;
      this.page_index = 0;
      this.offset = 0;
      this._organization ? this.getTransactions() : null;
    });
  };

  get collection(): string {
    return this._collection;
  }

  @Input('isShowFilter') isShowFilter: boolean = true;
  @Input('isShowExport') isShowExport: boolean = true;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public isloading: boolean = false;
  public page_limit: number = 10;
  public total_records: number = 0;
  public page_index: number = 0;
  public offset: number = 0;
  public transactions: Array<Transactions> = [];

  public errorMsg: string = '';
  public search: FormControl = new FormControl('');

  public sortBy = '';
  public sortOptionList = [
    { name: 'Date', value: 'date' },
    { name: 'Collectible Qty', value: 'total_tokens' },
    { name: 'Total Amount', value: 'total_amount' },
  ];

  public selectedPaymentStatus: string = '';
  public paymentStatus = [
    { value: '', name: 'All' },
    { value: 'new', name: 'New' },
    { value: 'initiated', name: 'Initiated' },
    { value: 'processing', name: 'Processing' },
    { value: 'completed', name: 'Completed' },
    { value: 'failed', name: 'Failed' },
    { value: 'rejected', name: 'Rejected' }
  ]

  public selectedMintStatus: string = '';
  public mintStatus = [
    { value: '', name: 'All' },
    { value: 'new', name: 'New' },
    { value: 'minting', name: 'Minting' },
    { value: 'minted', name: 'Minted' },
    { value: 'failed', name: 'Failed' },
    { value: 'rejected', name: 'Rejected' }
  ]

  constructor(
    public _organizationService: OrganizationService,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _toastService: ToastService,
    private _exportExcel: ExportExcelService
  ) { }

  ngOnInit(): void {
    if (this._router.url === '/dashboard') {
      this.page_limit = 5;
    }
    this._activatedRoute.queryParams.subscribe((params: Params): void => {
      if (params.organizationId) {
        this._organization = params.organizationId
      }
      this.selectedPaymentStatus = params.status ? params.status : '';
      this.selectedMintStatus = params.mint_status ? params.mint_status : '';
    });
    // this.getTransactions();
  }

  ngAfterViewInit(): void {
    this.search.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      distinctUntilChanged(),
      debounceTime(1000),
    ).subscribe((res) => {
      this.getTransactions();
    }, (err) => {
      this.errorMsg = err.msg;
    })
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  /* GET USER */
  get getUser(): User {
    return this._userService.user.getValue();
  }

  /* GET TRANSACTION DATA */
  getTransactions() {
    if (this._organization) {
      this.isloading = true;
      this._organizationService.getTransactions(this._organization, this._collection, this.offset, this.page_limit, this.sortBy, this.search.value, this.selectedPaymentStatus, this.selectedMintStatus).pipe(takeUntil(this._unsubscribeAll)).subscribe((resp) => {
        this.transactions = resp.docs;
        this.page_index = resp.offset / resp.limit;
        this.total_records = resp.totalDocs;
        this.isloading = false;
      });
    }
  }

  onSortBy() {
    this.getTransactions();
  }

  /* on page change */
  changePage(event) {
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {
        organizationId: this._organizationService.organization_id,
        page_limit: event.pageSize,
        offset: (event.pageIndex * event.pageSize),
      },
      queryParamsHandling: 'merge'
    });
    this.page_limit = event.pageSize;
    this.offset = (event.pageIndex * event.pageSize);
    this.getTransactions();
  }

  /** Payment Status Filter */
  public onPaymentStatusChange(status: string) {
    this.selectedPaymentStatus = status;
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {
        organizationId: this._organizationService.organization_id,
        offset: 0,
        status: status
      },
      queryParamsHandling: 'merge'
    });
    this.page_index = 0;
    this.offset = 0;
    this.getTransactions();
  }
  /** Mint Status Filter */
  public onMintStatusChange(status: string) {
    this.selectedMintStatus = status;
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {
        organizationId: this._organizationService.organization_id,
        offset: 0,
        mint_status: status
      },
      queryParamsHandling: 'merge'
    });
    this.page_index = 0;
    this.offset = 0;
    this.getTransactions();
  }

  /* GET POLYGON LINK */
  get polygonLink(): string {
    if (environment.production) {
      return `https://polygonscan.com/address/`;
    } else {
      return `https://mumbai.polygonscan.com/address/`;
    }
  }

  exportTransactions() {
    const headersMap = {
      '_id': 'Transaction ID',
      'wallet_holder_name': 'Name',
      'minted_wallet_address': 'Wallet',
      'email': 'Email',
      'name': 'Pack Name',
      'session_price': 'USD Price',
      'crypto_price': 'Crypto Price',
      'createdAt': 'Date & Time',
      'pack_quantity': 'Packs Qty',
      'total_tokens': 'Total Collectibles',
      'total_amount': 'Transaction Amount',
      'sub_total': 'Total',
      'status': 'Payment Status',
      'mint_status': 'Mint Status',
    };
    const data = this.transactions.map((item) => {
      return {
        wallet_holder_name: item?.wallet_holder_name,
        minted_wallet_address: item?.minted_wallet_address,
        email: item?.user?.email,
        name: item?.purchase_history?.pack?.name,
        session_price: item?.purchase_history?.pack?.price,
        crypto_price: item?.purchase_history?.pack?.crypto_price,
        createdAt: DateTime.fromISO(item?.createdAt).toFormat('dd MMM yyyy, h:mm a'),
        pack_quantity: item?.pack_quantity,
        total_tokens: item?.total_tokens,
        total_amount: item?.total_amount,
        sub_total: item?.sub_total,
        status: item?.status,
        mint_status: item?.mint_status,
        _id: item?._id
      };
    })
    this._exportExcel.exportToExcel(data, headersMap, this._organizationService.organization_name ? this._organizationService.organization_name + ' Transactions' : 'Transactions');
  }

  /** Copy Transaction Id */
  copyTransactionId(transactionId: string) {
    navigator.clipboard.writeText(transactionId).then(() => {
      this._toastService.openToast('Transaction ID Copied.');
    });
  }
}
