<form [formGroup]="form" (ngSubmit)="submit()">
    <div mat-dialog-content>
        <div>
            <app-image-preview [label]="'Upload Logo*'" (onSelect)="onSelectLogo($event)" [image_preview]="logoPreview" class="block w-full sm:w-80 m-auto mb-5">
                <p class="mt-4 text-center">Recommended image size should be 180px x 180px</p>
            </app-image-preview>
        </div>
        <div class="grid sm:grid-cols-2 gap-x-6 gap-y-6 md:gap-y-2 mt-2">
            <div class="flex">
                <mat-form-field
                    class="flex-auto gt-xs:pr-3">
                    <mat-label class="required">Host</mat-label>
                    <input matInput type="text" formControlName="host" placeholder="Host">
                    <mat-error *ngIf="form.get('host').hasError('required')">Host is required</mat-error>
                    <mat-error *ngIf="form.get('host').hasError('pattern')">Host is required</mat-error>
                </mat-form-field>
            </div>
            <div class="flex">
                <mat-form-field
                    class="flex-auto gt-xs:pr-3">
                    <mat-label>Username</mat-label>
                    <input matInput type="text" formControlName="username" placeholder="Username">
                    <mat-error *ngIf="form.get('username').hasError('required')">Username is required</mat-error>
                    <mat-error *ngIf="form.get('username').hasError('pattern')">Username is required</mat-error>
                </mat-form-field>
            </div>
            <div class="flex">
                <mat-form-field
                    class="flex-auto gt-xs:pr-3">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" autocomplete="new-password" formControlName="password" placeholder="Password">
                    <mat-error *ngIf="form.get('password').hasError('required')">Password is required</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('pattern')">Password is required</mat-error>
                </mat-form-field>
            </div>
            <div class="flex">
                <mat-form-field
                    class="flex-auto gt-xs:pr-3">
                    <mat-label>Default From</mat-label>
                    <input matInput type="text" formControlName="default_from" placeholder="Default From">
                    <mat-error *ngIf="form.get('default_from').hasError('required')">Default From is required</mat-error>
                    <mat-error *ngIf="form.get('default_from').hasError('pattern')">Default From is required</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="flex justify-end gap-x-5 mt-6">
        <ng-content></ng-content>
        <button mat-flat-button type="submit" [color]="'primary'" [disabled]="form.invalid">{{emailStatus == 'add' ? 'Save' : 'Update'}}</button>
    </div>
</form>