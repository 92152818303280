import { StandardResponse, StandardListing } from './common';
export interface UsersResponse extends StandardResponse {
    data: UserData;
}

export interface UserData extends StandardListing{
    docs: Array<User>;
}

export interface User {
    createdAt: string;
    email: string;
    email_verified: boolean;
    first_name: string;
    last_name: string;
    phone: string;
    organization?: string;
    role: string;
    updatedAt: string;
    __v: string;
    _id: string;
}

export enum roles {
    CLIENT = 'client',
    ADMIN = 'admin',
    CUSTOMER = 'customer'
}

export interface UserSummaryResponse extends StandardResponse {
    data: UserSummary
}

export interface UserSummary {
    user: {
        total_customers: number;
        total_admins: number;
        total_clients: number;
    }
    token: {
        total_minted: number;
        unqiue_holders: number;
    }
    purchase_rate: number;
}