import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import intlTelInput from 'intl-tel-input';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-intl-tel-inp',
  templateUrl: './intl-tel-inp.component.html',
  styleUrls: ['./intl-tel-inp.component.scss']
})
export class IntlTelInpComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input('field') field: FormControl;

  @ViewChild('telInput') telInput: ElementRef<HTMLInputElement>;
  private intl_ref: intlTelInput;
  private telInputSubject = new Subject();
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.intl_ref = intlTelInput(this.telInput.nativeElement, {
      // any initialisation options go here
      customContainer: 'w-full',
      formatOnDisplay: true,
      nationalMode: false,
      autoHideDialCode: true,
      autoPlaceholder: 'aggressive',
      hiddenInput: 'full_phone',
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.21/js/utils.min.js',
      separateDialCode: false
    });

    this.telInput.nativeElement.addEventListener('countrychange', () => {
      this.field.setValue(this.intl_ref.getNumber());
      this.setValidity(this.intl_ref.isValidNumber());
    });

    this.telInput.nativeElement.addEventListener('change', () => {
      this.field.setValue(this.intl_ref.getNumber());
      this.setValidity(this.intl_ref.isValidNumber());
    });

    this.telInput.nativeElement.addEventListener('keyup', () => {
      this.field.setValue(this.intl_ref.getNumber());
      this.setValidity(this.intl_ref.isValidNumber());
    });

    this.field.valueChanges.pipe(takeUntil(this.telInputSubject)).subscribe((res) => {
      if(this.intl_ref.isValidNumber(res)){
        this.telInput.nativeElement.focus();
        this.telInputSubject.next(null);
        this.telInputSubject.complete();
        let keyright = new KeyboardEvent('keyup', { key: 'ArrowRight', code: 'ArrowRight', keyCode: 39 });
        this.telInput.nativeElement.dispatchEvent(keyright);
        this.telInput.nativeElement.blur();
      }
    });
  }

  ngOnDestroy(): void {
    this.telInputSubject.next(null);
    this.telInputSubject.complete();
  }

  private setValidity(status){
    if(status){
      this.field.setErrors(null);
    } else {
      this.field.setErrors({incorrect: true});
    }
  }

}
