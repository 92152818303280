import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Organization } from 'app/interfaces/organization';
import { OrganizationService } from 'app/services/organization.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search-organization',
  templateUrl: './search-organization.component.html',
  styleUrls: ['./search-organization.component.scss']
})
export class SearchOrganizationComponent implements OnInit, OnDestroy {
  @Output('onSelect') onSelect = new EventEmitter;
  public isLoading: boolean = false;
  public filteredOrganization: Array<Organization> = [];
  public errorMsg: string = '';
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public _organizationService: OrganizationService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    if (this._organizationService.organization_id) {
      this._organizationService.organizationSingle(this._organizationService.organization_id).subscribe((res) => {
        this._organizationService.searchOrganization.setValue(res.company_name);
        this._organizationService.organization_name = res.company_name;
      }, (err) => {
        this.errorMsg = err.msg;
      })
    }

    this._organizationService.searchOrganization.valueChanges.pipe(
      filter(res => {
        return res != null && res.length >= 2
      }),
      takeUntil(this._unsubscribeAll),
      distinctUntilChanged(),
      debounceTime(1000),
      tap(() => {
        this.errorMsg = '';
        this.filteredOrganization = [];
        this.isLoading = true;
      }),
      switchMap(
        value => this._organizationService.organizationSearch(value).pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
      )
    ).subscribe((data) => {
      this.filteredOrganization = data;
    }, (error) => {
      this.errorMsg = error.msg;
      this.filteredOrganization = [];
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  public onSelected() {
    this._organizationService.organization_id = this._organizationService.searchOrganization.value;
    const org = this.filteredOrganization.find((org) => { return org._id == this._organizationService.organization_id });
    this._organizationService.searchOrganization.setValue(org.company_name);
    this._organizationService.organization_name = org.company_name;
    this.onSelect.emit(org);
  }

  public clearSelection() {
    this._organizationService.organization_id = '';
    this._organizationService.searchOrganization.reset();
    this._organizationService.organization_name = null;
    this.filteredOrganization = [];
    this._organizationService.searchOrganization.setValue(null);
    // Remove query params
    this._router.navigate([], {
      queryParams: {
        organizationId: null
      },
      queryParamsHandling: 'merge'
    })
    this.onSelect.emit(null);
  }

  /** Show Recent Organizations On Click */
  public onClick() {
    this._organizationService.organizationSearch(null, 5).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.filteredOrganization = res;
    }, (error) => {
      this.errorMsg = error.msg;
      this.filteredOrganization = [];
    })
  }

}
