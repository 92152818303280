import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { UserResponse } from 'app/core/user/user.types';
import { User } from 'app/interfaces/users';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    
    /* user information */
    public user: BehaviorSubject<User> = new BehaviorSubject(undefined);
    public user$: Observable<User> = this.user.asObservable();

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
        const user = JSON.parse(localStorage.getItem('user'));
        this.user.next(user);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<UserResponse>
    {
        return this._httpClient.get<UserResponse>(`${environment.baseUrl}/users/me`).pipe(
            tap((user) => {
                localStorage.setItem('user', JSON.stringify(user.data));
                this.user.next(user.data);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this._httpClient.patch<User>('api/common/user', {user}).pipe(
            map((response) => {
                this.user.next(response);
            })
        );
    }
}
