<!-- Payout Timeline -->
<ng-content></ng-content>
<div class="flex flex-col flex-auto sm:mb-18 overflow-auto">
    <div class="grid border-t overflow-auto">
        <!-- Header -->
        <div
            class="timeline-grid z-10 sticky top-0 grid gap-4 py-4 px-4 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5">
            <div>Amount</div>
            <div>Type</div>
            <div>Date Time</div>
            <div>Status</div>
        </div>
        <ng-container *ngIf="payoutTimeline && !isloading; else loading">
            <ng-container *ngIf="payoutTimeline as payoutTimeline">
                <ng-container *ngIf="payoutTimeline.length > 0; else noData">
                    <!-- Rows -->
                    <ng-container *ngFor="let payout of payoutTimeline; index as i">
                        <div class="timeline-grid grid items-center gap-4 py-4 px-4 border-b">
                            <div class="truncate" [matTooltip]="payout?.amount | currency" matTooltipPosition="below">
                                {{payout?.amount | currency}}
                            </div>
                            <div class="truncate" [matTooltip]="payout?.type | titlecase" matTooltipPosition="below">
                                {{payout?.type | titlecase}}
                            </div>
                            <div class="truncate" [matTooltip]="payout?.createdAt | date:'short'"
                                matTooltipPosition="below">
                                {{payout?.createdAt | date:'short'}}
                            </div>
                            <div class="truncate" [matTooltip]="payout?.status | titlecase" matTooltipPosition="below">
                                {{payout?.status | titlecase}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <mat-paginator *ngIf="isShowFilter" [ngClass]="{'pointer-events-none': isloading}" (page)="changePage($event)"
        class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
        [length]="total_records" [pageIndex]="page_index" [pageSize]="page_limit" [pageSizeOptions]="[5, 10, 25, 50]"
        [showFirstLastButtons]="true">
    </mat-paginator>

    <ng-template #noData>
        <div class="p-8 sm:p-16 border-t text-2xl sm:text-4xl font-semibold tracking-tight text-center">There is no
            Payout Timeline available.</div>
    </ng-template>

    <ng-template #loading>
        <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">Loading...</div>
    </ng-template>
</div>