import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fourDigit'
})
export class FourDigitPipe implements PipeTransform {

  transform(value: string): string {
    value = value.substr(value.length - 4);
    return value;
  }

}
