import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User, roles } from 'app/interfaces/users';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {
                this._userService.user.subscribe((user: User) => {
                    if(user){
                        if(user.role !== roles.ADMIN){
                            for (const key in navigation) {
                                navigation[key] = navigation[key].filter(element => {
                                    return (element.role && user.role === roles.CLIENT) || (element.role && element.role === 'client-only');
                                });
                            }
                            this._navigation.next(navigation);
                        } else {
                            for (const key in navigation) {
                                navigation[key] = navigation[key].filter(element => {
                                    return element.role !== 'client-only';
                                });
                            }
                            this._navigation.next(navigation);
                        }
                    } else {
                        this._navigation.next(null);
                    }
                });
            })
        );
    }
}
