import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PayoutTimeline } from 'app/interfaces/organization';
import { OrganizationService } from 'app/services/organization.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-payout-timeline',
  templateUrl: './payout-timeline.component.html',
  styleUrls: ['./payout-timeline.component.scss']
})
export class PayoutTimelineComponent implements OnInit, OnDestroy {
  @Input('isShowFilter') isShowFilter: boolean = true;
  @Input('organization_id') organization_id: string;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public isloading: boolean = false;
  public page_limit: number = 10;
  public total_records: number = 0;
  public page_index: number = 0;
  public offset: number = 0;
  public payoutTimeline: Array<PayoutTimeline> = [];

  constructor(
    private _router: Router,
    private _organizationService: OrganizationService
  ) { }

  ngOnInit(): void {
    if (this._router.url === '/dashboard') {
      this.page_limit = 5;
    }
    this.getPayout();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  getPayout() {
    if (this.organization_id) {
      this.isloading = true;
      this._organizationService.getPayoutTimeline(this.organization_id, this.page_limit, this.offset).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
        this.payoutTimeline = res.data.docs;
        this.isloading = false;
      });
    }
  }

  /* on page change */
  changePage(event) {
    this.page_limit = event.pageSize;
    this.offset = (event.pageIndex * event.pageSize);
    this.getPayout();
  }
}
