<div class="relative flex flex-col flex-0 sm:items-start sm:justify-between py-5 px-6 md:px-8 border-t">
    <!-- sort by -->
    <div class="flex flex-col-reverse md:flex-row items-center justify-between gap-4 w-full">
        <div class="flex" *ngIf="isShowExport">
            <button (click)="exportTransactions()" [disabled]="transactions.length <= 0 || isloading" mat-flat-button [color]="'primary'">
                <span>Export Transactions</span>
            </button>
        </div>
        <div class="flex flex-col sm:flex-row gap-4 items-end mb-2 w-full md:w-96 ml-auto mr-0 mt-4" *ngIf="isShowFilter">
            <mat-form-field appearance="fill" class="flex-auto w-full sm:w-auto">
                <mat-label>Search</mat-label>
                <input matInput [formControl]="search" placeholder="Search">
            </mat-form-field>
    
            <mat-form-field class="flex-auto gt-xs:pr-3 w-full sm:w-auto remove-space">
                <mat-label class="required">Sort by</mat-label>
                <mat-select [(ngModel)]="sortBy" (selectionChange)="onSortBy()">
                    <mat-option [value]="''">All</mat-option>
                    <ng-container *ngFor="let opt of sortOptionList">
                        <mat-option [value]="opt.value">{{opt.name | titlecase}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
<!-- Transaction -->
<div class="flex flex-col flex-auto sm:mb-18 overflow-auto">
    <div class="grid border-t overflow-auto">
        <!-- Header -->
        <div
            class="transaction-grid z-10 sticky top-0 grid gap-4 py-4 px-4 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5">
            <div>Name</div>
            <div>Wallet</div>
            <div>Email</div>
            <div>Pack Name</div>
            <div>Price</div>
            <div>Date & Time</div>
            <div>Packs Qty</div>
            <div>Total Collectibles</div>
            <div>Transaction Amount</div>
            <div>Total</div>
            <div class="flex items-center">
                Payment Status
                <div class="status-filter ml-2 w-4 h-4 cursor-pointer" [matMenuTriggerFor]="paymentStatusFilter">
                    <mat-icon class="h-4 min-h-4 hover:text-primary-500"
                        [svgIcon]="'heroicons_outline:filter'"></mat-icon>
                </div>
                <mat-menu #paymentStatusFilter="matMenu">
                    <mat-radio-group aria-labelledby="status-filter-label" class="status-filter-group"
                        [(ngModel)]="selectedPaymentStatus">
                        <p *ngFor="let status of paymentStatus">
                            <mat-radio-button class="status-filter-checkbox" [value]="status.value"
                                (change)="onPaymentStatusChange(status.value)">
                                {{status.name}}
                            </mat-radio-button>
                        </p>
                    </mat-radio-group>
                </mat-menu>
            </div>
            <div class="flex items-center">
                Mint Status
                <div class="status-filter ml-2 w-4 h-4 cursor-pointer" [matMenuTriggerFor]="mintStatusFilter">
                    <mat-icon class="h-4 min-h-4 hover:text-primary-500"
                        [svgIcon]="'heroicons_outline:filter'"></mat-icon>
                </div>
                <mat-menu #mintStatusFilter="matMenu">
                    <mat-radio-group aria-labelledby="status-filter-label" class="status-filter-group"
                        [(ngModel)]="selectedMintStatus">
                        <p *ngFor="let status of mintStatus">
                            <mat-radio-button class="status-filter-checkbox" [value]="status.value"
                                (change)="onMintStatusChange(status.value)">
                                {{status.name}}
                            </mat-radio-button>
                        </p>
                    </mat-radio-group>
                </mat-menu>
            </div>
        </div>
        <ng-container *ngIf="transactions && !isloading; else loading">
            <ng-container *ngIf="transactions as transactions">
                <ng-container *ngIf="transactions.length > 0; else noTransaction">
                    <!-- Rows -->
                    <ng-container *ngFor="let transaction of transactions; index as i">
                        <div class="transaction-grid grid items-center gap-4 py-4 px-4 border-b">
                            <div class="flex items-center">
                                <span class="truncate" [matTooltip]="transaction?.wallet_holder_name"
                                matTooltipPosition="below">
                                    {{transaction?.wallet_holder_name}}
                                </span>
                                <span class="copy-link ml-2 w-4 h-4 cursor-pointer" [matTooltip]="'Copy Transaction ID'" (click)="copyTransactionId(transaction?._id)">
                                    <mat-icon class="h-4 min-h-4 hover:text-primary-500" [svgIcon]="'heroicons_outline:link'"></mat-icon>
                                </span>
                            </div>
                            <div class="truncate" [matTooltip]="transaction?.minted_wallet_address"
                                matTooltipPosition="below">
                                <a class="underline text-blue-600"
                                    [href]="polygonLink+transaction?.minted_wallet_address+'#tokentxnsErc721'"
                                    target="_blank">{{transaction?.minted_wallet_address | fourDigit}}</a>
                            </div>
                            <div class="truncate" [matTooltip]="transaction?.user?.email" matTooltipPosition="below">
                                {{transaction?.user?.email}}
                            </div>
                            <div class="truncate" [matTooltip]="transaction?.purchase_history?.pack?.name"
                                matTooltipPosition="below">
                                {{transaction?.purchase_history?.pack?.name}}
                            </div>
                            <div *ngIf="transaction?.action == 'crypto_payment_and_mint' else packPrice" class="truncate">
                                <span *ngIf="transaction?.purchase_history?.session_price else cryptoPackPrice"
                                    class="flex items-center gap-x-2"
                                    [matTooltip]="transaction?.purchase_history?.session_price | currency: 'MATIC ':'symbol':'1.0-4'"
                                    matTooltipPosition="below">
                                    {{transaction?.purchase_history?.session_price | number:'1.0-4'}}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 256 256" fill="none">
                                        <path d="M186.384 171.561L243.497 138.588C246.518 136.842 248.397 133.591 248.397 130.099V64.1433C248.397 60.6566 246.518 57.4003 243.497 55.6543L186.384 22.6815C183.363 20.9356 179.605 20.9407 176.584 22.6815L119.47 55.6543C116.45 57.4003 114.571 60.6515 114.571 64.1433V182.001L74.5219 205.117L34.4733 182.001V135.752L74.5219 112.635L100.941 127.882V96.8601L79.4218 84.439C77.937 83.5839 76.2422 83.1334 74.5219 83.1334C72.8016 83.1334 71.1069 83.5839 69.6221 84.439L12.5034 117.417C9.48256 119.163 7.60352 122.414 7.60352 125.906V191.857C7.60352 195.343 9.48256 198.6 12.5034 200.346L69.617 233.323C72.6378 235.064 76.3907 235.064 79.4166 233.323L136.53 200.351C139.551 198.605 141.43 195.348 141.43 191.862V74.0044L142.152 73.5897L181.479 50.8876L221.527 74.0095V120.253L181.479 143.375L155.1 128.148V159.171L176.579 171.576C179.6 173.317 183.358 173.317 186.379 171.576L186.384 171.561Z" fill="url(#paint0_linear_4864_1937)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_4864_1937" x1="28.0733" y1="200.863" x2="235.024" y2="49.9609" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#A726C1"/><stop offset="0.88" stop-color="#803BDF"/><stop offset="1" stop-color="#7B3FE4"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </span>
                                <ng-template #cryptoPackPrice>
                                    <div class="flex items-center gap-x-2"
                                        [matTooltip]="transaction?.purchase_history?.pack?.crypto_price | currency: 'MATIC ':'symbol':'1.0-4'"
                                        matTooltipPosition="below">
                                        <span>{{transaction?.purchase_history?.pack?.crypto_price | number:'1.0-4'}}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 256 256" fill="none">
                                            <path d="M186.384 171.561L243.497 138.588C246.518 136.842 248.397 133.591 248.397 130.099V64.1433C248.397 60.6566 246.518 57.4003 243.497 55.6543L186.384 22.6815C183.363 20.9356 179.605 20.9407 176.584 22.6815L119.47 55.6543C116.45 57.4003 114.571 60.6515 114.571 64.1433V182.001L74.5219 205.117L34.4733 182.001V135.752L74.5219 112.635L100.941 127.882V96.8601L79.4218 84.439C77.937 83.5839 76.2422 83.1334 74.5219 83.1334C72.8016 83.1334 71.1069 83.5839 69.6221 84.439L12.5034 117.417C9.48256 119.163 7.60352 122.414 7.60352 125.906V191.857C7.60352 195.343 9.48256 198.6 12.5034 200.346L69.617 233.323C72.6378 235.064 76.3907 235.064 79.4166 233.323L136.53 200.351C139.551 198.605 141.43 195.348 141.43 191.862V74.0044L142.152 73.5897L181.479 50.8876L221.527 74.0095V120.253L181.479 143.375L155.1 128.148V159.171L176.579 171.576C179.6 173.317 183.358 173.317 186.379 171.576L186.384 171.561Z" fill="url(#paint0_linear_4864_1937)"/>
                                            <defs>
                                                <linearGradient id="paint0_linear_4864_1937" x1="28.0733" y1="200.863" x2="235.024" y2="49.9609" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#A726C1"/><stop offset="0.88" stop-color="#803BDF"/><stop offset="1" stop-color="#7B3FE4"/>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div>
                                </ng-template>
                            </div>
                            <ng-template #packPrice>
                                <div *ngIf="transaction?.purchase_history?.session_price else walletPackPrice"
                                    class="truncate"
                                    [matTooltip]="transaction?.purchase_history?.session_price | currency"
                                    matTooltipPosition="below">
                                    {{transaction?.purchase_history?.session_price | currency}}
                                </div>
                                <ng-template #walletPackPrice>
                                    <div class="truncate" [matTooltip]="transaction?.purchase_history?.pack?.price | currency" matTooltipPosition="below">
                                        {{transaction?.purchase_history?.pack?.price | currency}}
                                    </div>
                                </ng-template>
                            </ng-template>
                            <div class="truncate" [matTooltip]="transaction?.createdAt | date:'short'"
                                matTooltipPosition="below">
                                {{transaction?.createdAt | date:'short'}}
                            </div>
                            <div class="truncate" [matTooltip]="transaction?.pack_quantity" matTooltipPosition="below">
                                {{transaction?.pack_quantity}}
                            </div>
                            <div class="truncate" [matTooltip]="transaction?.total_tokens" matTooltipPosition="below">
                                {{transaction?.total_tokens}}
                            </div>
                            <div class="truncate"
                                [matTooltip]="transaction?.action == 'crypto_payment_and_mint' ? (transaction?.total_amount | currency: 'MATIC ':'symbol':'1.0-4') : (transaction?.total_amount | currency)"
                                matTooltipPosition="below">
                                <span *ngIf="transaction?.action == 'crypto_payment_and_mint'; else fiat_total" class="flex items-center gap-x-2">
                                    {{transaction?.total_amount | number:'1.0-4'}}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 256 256" fill="none">
                                        <path d="M186.384 171.561L243.497 138.588C246.518 136.842 248.397 133.591 248.397 130.099V64.1433C248.397 60.6566 246.518 57.4003 243.497 55.6543L186.384 22.6815C183.363 20.9356 179.605 20.9407 176.584 22.6815L119.47 55.6543C116.45 57.4003 114.571 60.6515 114.571 64.1433V182.001L74.5219 205.117L34.4733 182.001V135.752L74.5219 112.635L100.941 127.882V96.8601L79.4218 84.439C77.937 83.5839 76.2422 83.1334 74.5219 83.1334C72.8016 83.1334 71.1069 83.5839 69.6221 84.439L12.5034 117.417C9.48256 119.163 7.60352 122.414 7.60352 125.906V191.857C7.60352 195.343 9.48256 198.6 12.5034 200.346L69.617 233.323C72.6378 235.064 76.3907 235.064 79.4166 233.323L136.53 200.351C139.551 198.605 141.43 195.348 141.43 191.862V74.0044L142.152 73.5897L181.479 50.8876L221.527 74.0095V120.253L181.479 143.375L155.1 128.148V159.171L176.579 171.576C179.6 173.317 183.358 173.317 186.379 171.576L186.384 171.561Z" fill="url(#paint0_linear_4864_1937)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_4864_1937" x1="28.0733" y1="200.863" x2="235.024" y2="49.9609" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#A726C1"/><stop offset="0.88" stop-color="#803BDF"/><stop offset="1" stop-color="#7B3FE4"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </span>
                                <ng-template #fiat_total>
                                    {{transaction?.total_amount | currency}}
                                </ng-template>
                            </div>
                            <div class="truncate"
                                [matTooltip]="transaction?.action == 'crypto_payment_and_mint' ? (transaction?.sub_total | currency: 'MATIC ':'symbol':'1.0-4') : (transaction?.sub_total | currency)"
                                matTooltipPosition="below">
                                <span *ngIf="transaction?.action == 'crypto_payment_and_mint'; else fiat_subtotal" class="flex items-center gap-x-2">
                                    {{transaction?.sub_total | number:'1.0-4'}}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 256 256" fill="none">
                                        <path d="M186.384 171.561L243.497 138.588C246.518 136.842 248.397 133.591 248.397 130.099V64.1433C248.397 60.6566 246.518 57.4003 243.497 55.6543L186.384 22.6815C183.363 20.9356 179.605 20.9407 176.584 22.6815L119.47 55.6543C116.45 57.4003 114.571 60.6515 114.571 64.1433V182.001L74.5219 205.117L34.4733 182.001V135.752L74.5219 112.635L100.941 127.882V96.8601L79.4218 84.439C77.937 83.5839 76.2422 83.1334 74.5219 83.1334C72.8016 83.1334 71.1069 83.5839 69.6221 84.439L12.5034 117.417C9.48256 119.163 7.60352 122.414 7.60352 125.906V191.857C7.60352 195.343 9.48256 198.6 12.5034 200.346L69.617 233.323C72.6378 235.064 76.3907 235.064 79.4166 233.323L136.53 200.351C139.551 198.605 141.43 195.348 141.43 191.862V74.0044L142.152 73.5897L181.479 50.8876L221.527 74.0095V120.253L181.479 143.375L155.1 128.148V159.171L176.579 171.576C179.6 173.317 183.358 173.317 186.379 171.576L186.384 171.561Z" fill="url(#paint0_linear_4864_1937)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_4864_1937" x1="28.0733" y1="200.863" x2="235.024" y2="49.9609" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#A726C1"/><stop offset="0.88" stop-color="#803BDF"/><stop offset="1" stop-color="#7B3FE4"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </span>
                                <ng-template #fiat_subtotal>
                                    {{transaction?.sub_total | currency}}
                                </ng-template>
                            </div>
                            <div class="truncate" [matTooltip]="transaction?.status | titlecase"
                                matTooltipPosition="below">
                                {{transaction?.status | titlecase}}
                            </div>
                            <div class="truncate" [matTooltip]="transaction?.mint_status | titlecase"
                                matTooltipPosition="below">
                                {{transaction?.mint_status | titlecase}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <mat-paginator *ngIf="isShowFilter" [ngClass]="{'pointer-events-none': isloading}" (page)="changePage($event)"
        class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
        [length]="total_records" [pageIndex]="page_index" [pageSize]="page_limit" [pageSizeOptions]="[5, 10, 25, 50, 100, 200]"
        [showFirstLastButtons]="true">
    </mat-paginator>

    <ng-template #noTransaction>
        <div class="p-8 sm:p-16 border-t text-2xl sm:text-4xl font-semibold tracking-tight text-center">
            {{organization ? 'There is no transaction information available.' : 'Please select an organization to view transaction information.'}}</div>
    </ng-template>

    <ng-template #loading>
        <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">Loading...</div>
    </ng-template>
</div>