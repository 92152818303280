import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../user/user.service';
import { roles } from 'app/interfaces/users';

@Injectable({
  providedIn: 'root'
})
export class CanAccessGuard implements CanActivate {
  
  constructor(private _userService: UserService, private _router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const user = this._userService.user.getValue();
      if(user){
        if(user.role === roles.CLIENT){
          this._router.navigate(['/dashboard']);
          return false;
        }
        return true;
      }
      this._router.navigate(['/collections']);
      return false;
  }
  
}
