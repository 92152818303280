<div>
    <h3 class="text-{14px} font-medium mb-1" *ngIf="label">{{label}}</h3>
    <small *ngIf="format">Image size should be {{format}}</small>
    <div *ngIf="!image_preview" class="border-2 p-8 border-dashed h-56 border-gray-300 rounded-lg flex flex-col justify-center items-center">
        <mat-icon class="icon-size-12" [svgIcon]="'heroicons_outline:document-duplicate'"></mat-icon>
        <ng-content></ng-content>
        <div class="mt-6">
            <button
                mat-flat-button
                (click)="file.click()"
                type="button"
                [color]="'accent'">
                Select Image
            </button>
        </div>
    </div>

    <!-- IMAGE PREVIEW -->
    <div *ngIf="image_preview" class="border-2 p-3 border-dashed border-gray-300 rounded-lg flex flex-col justify-center items-center">
        <div class="relative">
            <img [src]="image_preview" class="h-56 w-56" alt="Collectibles Preview">
            <button *ngIf="closeBtn" type="button" (click)="removeImage()" class="absolute bg-white rounded-full h-8 w-8 flex flex-col justify-center items-center icon-btn" matTooltip="Remove Image">
                <mat-icon [svgIcon]="'heroicons_outline:x'" [color]="'warn'" class="icon-size-5"></mat-icon>
            </button>
            <div *ngIf="!closeBtn" class="text-center mt-4">
                <button
                    mat-flat-button
                    (click)="file.click()"
                    type="button"
                    [color]="'accent'">
                    Replace Image
                </button>
            </div>
        </div>
    </div>
</div>

<input type="file" #file (change)="filesSelected($event.target.files[0])" hidden accept="image/png, image/gif, image/jpeg, image/webp">