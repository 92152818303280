import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OrganizationService } from 'app/services/organization.service';
import { DateTime } from 'luxon';
import { ApexOptions } from 'ng-apexcharts';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-summary-graph',
  templateUrl: './summary-graph.component.html',
  styleUrls: ['./summary-graph.component.scss']
})
export class SummaryGraphComponent implements OnInit, OnDestroy {
  @Input('organization_id') organization_id: string;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public earnedChart$: ApexOptions = null;
  public earnedChartMatic: ApexOptions = null;
  public soldChart: ApexOptions = null;
  public graph_crypto_dates: Array<string> = [];
  public graph_fiat_dates: Array<string> = [];
  public graph_final_dates: Array<string> = [];
  public graph_crypto_earned: Array<number> = [];
  public graph_fiat_earned: Array<number> = [];
  public graph_crypto_sold: Array<number> = [];
  public graph_fiat_sold: Array<number> = [];

  public packChart: ApexOptions = null;
  public graph_crypto_packs: Array<number> = [];
  public graph_fiat_packs: Array<number> = [];
  public unique_crypto_packs: Array<number> = [];
  public unique_fiat_packs: Array<number> = [];

  constructor(
    private _organizationService: OrganizationService,
  ) { }

  ngOnInit(): void {
    this.getGraphData();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  /* GET GRAPH DATA */
  public getGraphData() {
    this._organizationService.getTransactionGraph(this.organization_id).pipe(takeUntil(this._unsubscribeAll)).subscribe((resp) => {
      this.graph_crypto_dates = resp.crypto.map((r) => DateTime.fromISO(r._id).toFormat('dd MMM'));
      this.graph_fiat_dates = resp.fiat.map((r) => DateTime.fromISO(r._id).toFormat('dd MMM'));
      this.graph_final_dates = [...new Set([...this.graph_crypto_dates, ...this.graph_fiat_dates])];
      this.graph_crypto_earned = resp.crypto.map((r) => r.sub_total);
      this.graph_fiat_earned = resp.fiat.map((r) => r.sub_total);
      this.graph_crypto_sold = resp.crypto.map((r) => r.total_tokens);
      this.graph_fiat_sold = resp.fiat.map((r) => r.total_tokens);
      this.graph_crypto_packs = resp.crypto.map((r) => r.total_packs_quantity);
      this.graph_fiat_packs = resp.fiat.map((r) => r.total_packs_quantity);
      this.unique_crypto_packs = resp.crypto.map((r) => r.total_unique_packs);
      this.unique_fiat_packs = resp.fiat.map((r) => r.total_unique_packs);

      // EARNED $
      this.earnedChart$ = {
        chart: {
          animations: {
            enabled: false
          },
          height: '180',
          type: 'area',
          sparkline: {
            enabled: true
          },
          stacked: false,
          zoom: {
            enabled: false
          },
        },
        colors: ['#34D399'],
        fill: {
          colors: ['#34D399'],
          opacity: 0.5
        },
        series: [
          {
            name: 'Fiat Earned',
            data: this.graph_fiat_earned
          }
        ],
        stroke: {
          curve: 'smooth'
        },
        tooltip: {
          followCursor: false,
          theme: 'dark',
          fixed: {
            enabled: true,
            position: 'topLeft',
            offsetX: 0,
            offsetY: 0,
          },
        },
        xaxis: {
          type: 'category',
          categories: this.graph_fiat_dates,
        },
        // yaxis  : {
        //     labels: {
        //         formatter: (val): string => val.toString()
        //     }
        // }
      };

      // EARNED MATIC
      this.earnedChartMatic = {
        chart: {
          animations: {
            enabled: false
          },
          height: '180',
          type: 'area',
          sparkline: {
            enabled: true
          },
          stacked: false,
          zoom: {
            enabled: false
          },
        },
        colors: ['#ffa600'],
        fill: {
          colors: ['#ffa600'],
          opacity: 0.5
        },
        series: [
          {
            name: 'Crypto Earned',
            data: this.graph_crypto_earned
          }
        ],
        stroke: {
          curve: 'smooth'
        },
        tooltip: {
          followCursor: false,
          theme: 'dark',
          fixed: {
            enabled: true,
            position: 'topLeft',
            offsetX: 0,
            offsetY: 0,
          },
          y: {
            formatter: function (val) {
              return (val).toFixed(4);
            }
          },
        },
        xaxis: {
          type: 'category',
          categories: this.graph_crypto_dates,
        },
        // yaxis: {
        //   labels: {
        //     formatter: (val): string => val.toString()
        //   }
        // }
      };

      // SOLD
      this.soldChart = {
        chart: {
          animations: {
            enabled: false
          },
          height: '180',
          type: 'area',
          sparkline: {
            enabled: true
          },
          stacked: false,
          zoom: {
            enabled: false
          },
        },
        colors: ['#38BDF8', '#de425b'],
        fill: {
          colors: ['#38BDF8'],
          opacity: 0.5
        },
        series: [
          {
            name: 'Crypto Sold',
            data: this.graph_crypto_sold
          },
          {
            name: "Fiat Sold",
            data: this.graph_fiat_sold
          }
        ],
        stroke: {
          curve: 'smooth'
        },
        tooltip: {
          followCursor: false,
          theme: 'dark',
          fixed: {
            enabled: true,
            position: 'topLeft',
            offsetX: 0,
            offsetY: 0,
          },
        },
        xaxis: {
          type: 'category',
          categories: this.graph_final_dates,
        },
        // yaxis: {
        //   labels: {
        //     formatter: (val): string => val.toString()
        //   }
        // }
      };

      // Token
      this.packChart = {
        chart: {
          animations: {
            enabled: false
          },
          height: '180',
          type: 'area',
          sparkline: {
            enabled: true
          },
          stacked: false,
          zoom: {
            enabled: false
          },
        },
        colors: ['#7467f0', '#fa42b5', '#ff714c', '#ffa600'],
        fill: {
          colors: ['#7467f0'],
          opacity: 0.5
        },
        series: [
          {
            name: 'Total Crypto Packs',
            data: this.graph_crypto_packs
          },
          {
            name: 'Total Fiat Packs',
            data: this.graph_fiat_packs
          },
          {
            name: 'Unique Crypto Packs',
            data: this.unique_crypto_packs
          },
          {
            name: 'Unique Fiat Packs',
            data: this.unique_fiat_packs
          }
        ],
        stroke: {
          curve: 'smooth'
        },
        tooltip: {
          followCursor: false,
          theme: 'dark',
          fixed: {
            enabled: true,
            position: 'topLeft',
            offsetX: 0,
            offsetY: 0,
          },
        },
        xaxis: {
          type: 'category',
          categories: this.graph_final_dates,
        },
        // yaxis  : {
        //     labels: {
        //         formatter: (val): string => val.toString()
        //     }
        // }
      };
    })
  }

  /* get Total Earned */
  get totalEarnedCrypto(): number {
    return this.graph_crypto_earned.reduce((partialSum, a) => partialSum + a, 0);
  }

  get totalEarnedFiat(): number {
    return this.graph_fiat_earned.reduce((partialSum, a) => partialSum + a, 0);
  }

  /* get Total Sold */
  get totalSold(): number {
    let totalSold;
    totalSold = this.graph_crypto_sold.reduce((partialSum, a) => partialSum + a, 0) + this.graph_fiat_sold.reduce((partialSum, a) => partialSum + a, 0);
    return totalSold;
  }

  /* get Total Packs */
  get totalPacks(): number {
    let totalPacks;
    totalPacks = this.graph_crypto_packs.reduce((partialSum, a) => partialSum + a, 0) + this.graph_fiat_packs.reduce((partialSum, a) => partialSum + a, 0);
    return totalPacks
  }
}
