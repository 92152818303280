import { Injectable } from '@angular/core';
import { WebApisService } from './web-apis.service';
import { environment } from 'environments/environment';
import { Collection, CollectionListing, CollectionResponse } from 'app/interfaces/collection';
import { Token, TokenImage, TokenTypes } from 'app/interfaces/tokens';
import { Pack, PackListingResponse } from 'app/interfaces/pack';
import { Observable, map } from 'rxjs';
import { LoopState } from 'app/enums/loopstate';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  public upload_percentage: number = 0;
  public upload_count: number = 0;
  public collection_details: Collection = undefined;
  public nft_types: Array<string> = [];
  public nft_categories: Array<string> = [];

  public delete_count: number = 0;
  public total_delete: number = 0;
  public delete_percentage: number = 0;

  public loopState: LoopState = LoopState.Completed;
  public nft_images: File[] = [];
  public nft_meta_data: Object[] = [];
  public loopProgress: number = 0;
  public currentIndex: number = 0; // Track the current index

  public upload_section_type: 'bulk' | 'single';

  public pack_upload_percentage: number = 0;
  public pack_upload_count: number = 0;
  public packLoopState: LoopState = LoopState.Completed;
  public pack_images: File[] = [];
  public pack_meta_data: Object[] = [];
  public packLoopProgress: number = 0;
  public packCurrentIndex: number = 0; // Track the current index
  public uploadPack_section_type: 'bulk' | 'single';

  constructor(
    private _webApi: WebApisService
  ) { }

  /* Get Collection List */
  public getCollectionListing(organizationId: string) {
    return this._webApi.get<CollectionListing>(`${environment.baseUrl}/collections?organization_id=${organizationId}`).pipe(
      map((resp) => {
        return resp.data.docs;
      })
    );
  }

  /* Create Collection */
  public createCollection(collection) {
    return this._webApi.post(`${environment.baseUrl}/collections`, collection);
  }

  /* Update Collection */
  public updateCollection(collectionId: string, collection) {
    return this._webApi.put(`${environment.baseUrl}/collections/${collectionId}`, collection);
  }

  /* Get Collection By Id */
  public getCollection(collectionId: string) {
    return this._webApi.get<CollectionResponse>(`${environment.baseUrl}/collections/${collectionId}`).pipe(
      map((resp) => {
        return resp.data;
      })
    );
  }

  /* UPLOAD NFT IMAGE */
  public uploadNftImage(organizationId: string, file: File): Observable<TokenImage> {
    const formdata = new FormData();
    formdata.append('file', file);
    return this._webApi.post(`${environment.baseUrl}/files/nft?organization_id=${organizationId}`, formdata as any).pipe(
      map((resp) => { return resp.data })
    );
  }

  /* GET TOKEN LISTING */
  public getTokens(collectionId: string, offset: number, limit: number) {
    return this._webApi.get(`${environment.baseUrl}/tokens?collection_id=${collectionId}&limit=${limit}&offset=${offset}`);
  }

  /* CREATE NFT */
  public addNFT(nft) {
    return this._webApi.post(`${environment.baseUrl}/tokens`, nft);
  }

  /* UPDATE NFT */
  public updateNFT(nft_id: string, nft: Token) {
    return this._webApi.put(`${environment.baseUrl}/tokens/${nft_id}`, nft);
  }

  /* GET NFT BY ID */
  public getNFTByID(nftid: string) {
    return this._webApi.get(`${environment.baseUrl}/tokens/${nftid}`);
  }

  /* DELETE NFT */
  public deleteNFT(nftid: string) {
    return this._webApi.delete(`${environment.baseUrl}/tokens/${nftid}`, null);
  }

  /* UPLOAD IMAGE */
  public uploadImage(file: File, organizationId: string) {
    const formdata = new FormData();
    formdata.append('file', file);
    return this._webApi.post(`${environment.baseUrl}/files?organization_id=${organizationId}`, formdata as any).pipe(
      map((resp) => { return resp.data })
    );
  }

  /* GET PACKS */
  public getPacks(collection_id: string, offset: number, limit: number) {
    return this._webApi.get<PackListingResponse>(`${environment.baseUrl}/packs?collection_id=${collection_id}&offset=${offset}&limit=${limit}`);
  }

  /* DELTE PACK */
  public deletePack(pack_id: string) {
    return this._webApi.delete(`${environment.baseUrl}/packs/${pack_id}`, null);
  }

  /* CREATE PACK */
  public createPack(pack: Pack) {
    return this._webApi.post(`${environment.baseUrl}/packs`, pack);
  }

  /* UPDATE PACK */
  public updatePack(pack_id: string, pack: Pack) {
    return this._webApi.put(`${environment.baseUrl}/packs/${pack_id}`, pack);
  }

  /* ENABLED/DISABLED COLLECTION */
  public toggleEnable(collection_id: string, status: boolean) {
    return this._webApi.put(`${environment.baseUrl}/collections/${collection_id}/toggle-enable`, { is_enabled: status });
  }

  /* PUBLISH COLLECTION */
  public publishCollection(collection_id: string) {
    return this._webApi.post(`${environment.baseUrl}/collections/${collection_id}/publish`, null);
  }

  /* SET COLLECTION POSITION */
  public setCollectionPosition(collections) {
    return this._webApi.put(`${environment.baseUrl}/collections/bulk-patch`, {
      collections: collections
    });
  }

  /* SET POSITION */
  public setPosition(packSort, collectionId: string) {
    return this._webApi.put(`${environment.baseUrl}/packs/bulk-set-position`, {
      packs: packSort,
      collection_id: collectionId
    });
  }

  /* GET NFT PROPERTY TYPES */
  public getTokenTypes(collectionId: string) {
    return this._webApi.get<TokenTypes>(`${environment.baseUrl}/tokens/property-types?collection=${collectionId}`).pipe(
      map((resp) => {
        return resp.data;
      })
    );
  }

  /** GET MATIC TO USD */
  public maticToUSD() {
    return this._webApi.get(`${environment.baseUrl}/wallets/token-price`).pipe(
      map((resp: any) => { return resp.data.usd_price })
    );
  }
}
