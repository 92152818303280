import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for access token
     */
    set sessionKey(key: string)
    {
        localStorage.setItem('sessionKey', key);
    }

    get sessionKey(): string
    {
        return localStorage.getItem('sessionKey') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post(`${environment.baseUrl}/auth/forgot_password`, {email : email});
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(data): Observable<any>
    {
        return this._httpClient.post(`${environment.baseUrl}/auth/reset`, {
            password: data.password,
            email: data.email,
            code: data.code
        });
    }

    /**
     * verify code
     *
     * @param email
     */
    resendCode(data): Observable<any>
    {
        return this._httpClient.post(`${environment.baseUrl}/auth/verify/again`, {email: data});
    }

    /**
     * resend code
     *
     * @param code
     * @param email
     */
    verifyCode(data): Observable<any>
    {
        return this._httpClient.post(`${environment.baseUrl}/auth/reset/check-validity`, {
            email: data.email,
            code: data.code
        });
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${environment.baseUrl}/auth/login`, credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.data.access_token;

                // Store the sesssion key in the local storage
                this.sessionKey = response.data.refresh_session_key;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            this._authenticated = true;
            return of(false);
        } else {
            this._authenticated = false;
            return of(true);
        }
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Remove the session key from local storage
        localStorage.removeItem('sessionKey');

        // remove user info from local storage
        localStorage.removeItem('user');
        this._userService.user.next(null);

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Refresh Token
     * 
     * @param session_key = string
     */
    refreshToken(session_key: string): Observable<{access_token: string, refresh_session_key: string}>
    {
        return this._httpClient.get(`${environment.baseUrl}/auth/refresh-session/${session_key}`).pipe(
            map((resp: any) => {
                return resp.data;
            })
        );
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
