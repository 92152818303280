import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(private _authService: AuthService, private _router: Router) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (this._authService.accessToken) {
      newReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken),
        body: req.method !== 'GET' ? this.cleanEmpty(req.body) : req.body,
      });
    }

    // Response
    return next.handle(newReq).pipe(
      catchError((error) => {
        if(error instanceof HttpErrorResponse && error.error.message === 'jwt expired') {
          // Refresh token
          return this._authService.refreshToken(this._authService.sessionKey).pipe(
            switchMap((response: any) => {
              // Save new access token
              this._authService.accessToken = response.access_token;
              this._authService.sessionKey = response.refresh_session_key;

              // Clone the original request with new access token
              const retryReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken),
                body: req.method !== 'GET' ? this.cleanEmpty(req.body) : req.body,
              });

              // Retry original request with new access token
              return next.handle(retryReq);
            }),
            catchError((err) => {
              // Sign out user if refresh token fails
              this._authService.signOut();

              // Redirect to login page
              this._router.navigate(['/login']);

              return throwError(err);
            })
          );
        } else if (error instanceof HttpErrorResponse && error.status === 401) {
          // Catch "401 Unauthorized" responses
            // Sign out
            this._authService.signOut();

            let pages = ['/forgot-password', '/sign-in']

            if(!pages.includes(location.pathname)){
                // Reload the app
                this._router.navigateByUrl('/sign-in');
            }
        } else {
          return throwError(error);
        }
      })
    );
  }

  /* remove null value and empty string */
  cleanEmpty = obj => {
    if (obj) {
      if (Array.isArray(obj)) {
        return obj
          .map(v => (v && typeof v === 'object') ? this.cleanEmpty(v) : v)
          .filter(v => !(v == null || v === ''));
      } else if (obj instanceof FormData) {
        return obj;
      } else {
        return Object.entries(obj)
          .map(([k, v]) => [k, v && typeof v === 'object' ? this.cleanEmpty(v) : v])
          .reduce((a, [k, v]) => ((v == null || v === '' || (typeof v === 'object' && Object.keys(v).length === 0)) ? a : (a[k] = v, a)), {});
      }
    } else {
      return obj;
    }

    // if (Array.isArray(obj) || typeof obj === 'bigint') {

    // } else {
    //   return obj;
    // }
  }
}
