export const EMAIL_VALIDATION: RegExp = /^[\w.+-]{1,64}@[a-zA-Z]{1,40}\.[a-zA-Z]{2,3}([a-zA-Z]{2})?$/;
export const PASSWORD_VALIDATION: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/;
export const CHARACTER_ONLY: RegExp = /^(?=.*\S)[a-zA-Z]+$/;
export const CHARACTER_AND_NUMBER: RegExp = /^(?=.*\S)[0-9a-zA-Z\s]+$/;
export const PHONE_US_VALIDATION: RegExp = /^(?:\+1|1)(\d{10})$/;
// export const CHARACTER_WITH_SPACE: RegExp = /^(?!.*\s$)([a-zA-Z]+[\s]?)*[a-zA-Z]+$/;
export const ACCEPT_ALL: RegExp = /^(?=.*\S)[a-zA-Z0-9\s\W]*$/;
export const NUMBER_ONLY: RegExp = /^\d+$/;
export const FLOAT_NUMBER: RegExp = /^[0-9]+(\.[0-9]+)?$/;
export const CHARACTER_WITH_SPACE: RegExp = /^(?=.*\S)[a-zA-Z\s]+$/;
export const URL_VALIDATION: RegExp = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,}(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
export const EIN_VALIDATION: RegExp = /^\d{2}\-?\d{7}$/;

export const DATE_FORMAT: RegExp = new RegExp('^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$');
export const DEFAULT_FROM: RegExp = /^.+ <[\w.+-]{1,64}@[a-zA-Z]{1,40}\.[a-zA-Z]{2,3}([a-zA-Z]{2})?>$/;