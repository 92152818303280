import { Injectable } from '@angular/core';
import { WebApisService } from './web-apis.service';
import { environment } from 'environments/environment';
import { Graph, OrganizationListing, OrganizationResponse, PayoutTimelineListing } from 'app/interfaces/organization';
import { map } from 'rxjs';
import { TransactionSummary, TransactionsListing } from 'app/interfaces/transaction';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  public organization_id: string = '';
  public organization_name: string = '';
  public searchOrganization: FormControl = new FormControl(null);

  constructor(
    private _webApi: WebApisService
  ) { }

  /* get Organization Listing */
  public organizationListing(offset: number, limit: number, searchTerm: string){
    if(searchTerm){
      return this._webApi.get<OrganizationListing>(`${environment.baseUrl}/organizations?offset=${offset}&limit=${limit}&search=${searchTerm}`);
    } else {
      return this._webApi.get<OrganizationListing>(`${environment.baseUrl}/organizations?offset=${offset}&limit=${limit}`);
    }
  }

  /* create business identity */
  public createBusinessIdentity(business_identity){
    return this._webApi.post<OrganizationResponse>(`${environment.baseUrl}/organizations`, business_identity);
  }

  /* get Organization info */
  public getOrganization(organizationId){
    return this._webApi.get<OrganizationResponse>(`${environment.baseUrl}/organizations/${organizationId}`);
  }

  /* update Organization */
  public updateOrganization(organizationId: string, organization: OrganizationResponse){
    return this._webApi.put<OrganizationResponse>(`${environment.baseUrl}/organizations/${organizationId}`, organization);
  }
  
  /* GET TRANSACTIONS */
  public getTransactions(organizationId: string, collection_id: string, offset: number, limit: number, sortby: string, search?: string, paymentStatus?: string, mintStatus?: string){
    let apiUrl = `${environment.baseUrl}/transactions?organization_id=${organizationId}&offset=${offset}&limit=${limit}`
    if(collection_id){
      apiUrl += `&collection_id=${collection_id}`;
    }
    if(sortby){
      apiUrl += `&sort_by=${sortby}`;
    }
    if(search) {
      apiUrl += `&search=${search}`;
    }
    if(paymentStatus) {
      apiUrl += `&status=${paymentStatus}`;
    }
    if(mintStatus) {
      apiUrl += `&mint_status=${mintStatus}`;
    }
    return this._webApi.get<TransactionsListing>(apiUrl).pipe(
      map((resp) => {
        return resp.data;
      })
    );
  }

  /* GET TRANSACTIONS SUMMARY */
  public getTransactionSummary(organizationId: string, collection_id: string){
    let apiUrl = `${environment.baseUrl}/transactions/report?organization_id=${organizationId}`;
    if(collection_id){
      apiUrl += `&collection_id=${collection_id}`;
    }
    return this._webApi.get<TransactionSummary>(apiUrl).pipe(
      map((resp) => {
        return resp.data;
      })
    );
  }


  /* get Organization Listing on Search */
  public organizationSearch(searchTerm?: string, limit?: number, offset: number = 0){
    let apiUrl = `${environment.baseUrl}/organizations?offset=${offset}`;
    if(searchTerm){
      apiUrl += `&search=${searchTerm}`;
    }
    if(limit){
      apiUrl += `&limit=${limit}`;
    }
    return this._webApi.get<OrganizationListing>(apiUrl).pipe((
      map((resp) => {
        return resp.data.docs
      })
    ));
  }

  /* get Transaction Graph */
  public getTransactionGraph(organizationId: string){
    return this._webApi.get<Graph>(`${environment.baseUrl}/transactions/graphs?organization_id=${organizationId}`).pipe((
      map((resp) => {
        return resp.data;
      })
    ));
  }

  /** Add Email Configuration */
  public sendEmailConfiguration(organizationId: string, emailConfiguration) {
    return this._webApi.post(`${environment.baseUrl}/organizations/${organizationId}/email-configuration`, emailConfiguration);
  }

  /** Get Single Organization */
  public organizationSingle(organizationId: string){
    return this._webApi.get<OrganizationResponse>(`${environment.baseUrl}/organizations/${organizationId}`).pipe((
      map((resp) => {
        return resp.data;
      })
    ))
  }

  /** Add Organization Payout */
  public createPayout(payout) {
    return this._webApi.post(`${environment.baseUrl}/payouts`, payout);
  }

  /** Get Organization Payout */
  public getPayoutTimeline(organizationId: string, limit: number, offset: number) {
    return this._webApi.get<PayoutTimelineListing>(`${environment.baseUrl}/payouts?organization=${organizationId}&limit=${limit}&offset=${offset}`);
  }
}
