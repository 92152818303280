/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        role :  'client-only',
        link : '/dashboard'
    },
    {
        id   : 'business-identity',
        title: 'Business Identity',
        type : 'basic',
        icon : 'heroicons_outline:user-circle',
        link : '/business-identity'
    },
    {
        id   : 'collections',
        title: 'Collections',
        role :  'client',
        type : 'basic',
        icon : 'heroicons_outline:view-grid-add',
        link : '/collections'
    },
    {
        id   : 'transactions',
        title: 'Transactions',
        role :  'client',
        type : 'basic',
        icon : 'heroicons_outline:menu-alt-1',
        link : '/transactions'
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        icon : 'heroicons_outline:users',
        link : '/users'
    },
    {
        id   : 'subscribers',
        title: 'Subscribers',
        type : 'basic',
        role :  'client',
        icon : 'heroicons_outline:bell',
        link : '/subscribers'
    },
    {
        id   : 'affiliates',
        title: 'Affiliates',
        type : 'basic',
        role :  'client',
        icon : 'heroicons_outline:sparkles',
        link : '/affiliates'
    },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'basic',
        icon : 'heroicons_outline:cog',
        role :  'client-only',
        link : '/settings'
    },
    {
        id   : 'games-configuration',
        title: 'Games Configuration',
        role :  'client',
        type : 'basic',
        icon : 'heroicons_outline:puzzle',
        link : '/games'
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
