import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class WebApisService {

  constructor(private http: HttpClient) {
  }

  get<T>(url: string) {
    return this.http.get<T>(url)
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
  }

  post<T>(url: string, dto: T) {
    return this.http.post<T>(url, dto)
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  delete<T>(url: string, dto: any) {
    return this.http.delete<T>(url,{body: dto})
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  put<T>(url: string, dto: T) {
    return this.http.put<T>(url, dto)
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  handleError(errorResp: HttpErrorResponse) {
    if (errorResp.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // debugger;
      console.error('An error occurred:', errorResp.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // return throwError(error);
    }
    // return an observable with a user-facing error message
    if(errorResp.status == 422){
      const errors = errorResp.error.error;
      let errorMessage: Array<string> = [];
      if(typeof(errors) == 'object'){
        
        let key = Object.keys(errors)[0];
        if(new Object(Object(errors[key])).hasOwnProperty('message')){
          errorMessage.push(errors[key].message);
        } else {
          errorMessage.push(errorResp.error.message);
        }
      } else if(Array.isArray(errors)){
        errorMessage.push(errors[0].message);
      }
      return throwError(
        errorMessage.toString()
      )
    } else if(errorResp.status == 409){
      let errorMessage: Array<string> = [];
      if(typeof(errorResp.error.error) === 'object' && !isEmpty(errorResp.error.error)){
        errorMessage.push(errorResp.error.error.message);
        return throwError(
          errorMessage.toString()
        )
      } else {
        return throwError(
          errorResp.error.message
        )
      }
    } else if(errorResp.status == 400){
      let errorMessage: Array<string> = [];
      if(typeof(errorResp.error.error) === 'object' && !isEmpty(errorResp.error.error)){
        for (const key in errorResp.error.error) {
          if(Array.isArray(errorResp.error.error[key]) && key !== 'message'){
            errorMessage.push(errorResp.error.error[key][0]);
            break;
          }
        }
        if(errorMessage.length == 0){
          errorMessage.push(errorResp.error.error.message);
          return throwError(
            errorMessage.toString()
          )
        } else {
          return throwError(
            errorMessage.toString()
          )
        }
      } else {
        return throwError(
          errorResp.error.message
        )
      }
    } else {
      return throwError(
        errorResp.error.message
      )
    }
    // return throwError(
    //   errorResp);
  }
}
