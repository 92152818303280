import { Injectable } from '@angular/core';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private _fuseConfirmationService: FuseConfirmationService
  ) { }

  public successAlert(message: string, title?: string){
    this._fuseConfirmationService.open({
      title  : title ? title : 'Alert',
      icon: {
        show: true,
        color: 'success',
        name: 'heroicons_outline:check'
      },
      message: `${message}`,
      actions: {
          cancel: {
            label: 'OK'
          },
          confirm: {
            show: false
          }
      }
    });
  }

  public errorAlert(error: string){
    this._fuseConfirmationService.open({
      title  : 'Error',
      icon: {
        show: true,
        color: 'warn',
        name: 'feather:alert-triangle'
      },
      message: `${error}`,
      actions: {
          cancel: {
            label: 'OK'
          },
          confirm: {
            show: false
          }
      }
    });
  }

  public confirmAlert(question: string, confirm_button_text?: string, cancel_button_text?: string): Promise<string>{
    return this._fuseConfirmationService.open({
      title  : 'Warning',
      icon: {
        show: true,
        color: 'warn',
        name: 'feather:alert-triangle'
      },
      message: `${question}`,
      actions: {
          cancel: {
            label: cancel_button_text ? cancel_button_text : 'No',
            show: true
          },
          confirm: {
            label: confirm_button_text ? confirm_button_text : 'Yes',
            show: true
          }
      }
    }).afterClosed().toPromise();
  }
}
