import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ACCEPT_ALL } from 'app/mixin/validation';
import { AlertService } from 'app/services/alert.service';
import { CollectionService } from 'app/services/collection.service';
import { OrganizationService } from 'app/services/organization.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-email-setting',
  templateUrl: './email-setting.component.html',
  styleUrls: ['./email-setting.component.scss']
})
export class EmailSettingComponent {
  private logoImage: File = undefined;
  public logoPreview: string = undefined;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public emailStatus: string = 'add';

  @Input('organizationId') organizationId: string;
  @Output('closeModal') closeModal = new EventEmitter();

  public form: FormGroup = new FormGroup({
    logo: new FormControl(null, Validators.required),
    host: new FormControl(null, [Validators.required, Validators.pattern(ACCEPT_ALL)]),
    username: new FormControl(null, [Validators.required, Validators.pattern(ACCEPT_ALL)]),
    password: new FormControl(null, [Validators.required, Validators.pattern(ACCEPT_ALL)]),
    default_from: new FormControl(null, [Validators.required, Validators.pattern(ACCEPT_ALL)])
  });

  constructor(
    private _organizationService: OrganizationService,
    private _collectionService: CollectionService,
    private _alertService: AlertService
  ) { }

  ngOnInit(): void {
    this._organizationService.getOrganization(this.organizationId).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      if(res.data.email_config){
        this.emailStatus = 'update';
        const logo = res.data.email_config?.logo;
        this.form.patchValue({
          logo: logo,
          host: res.data.email_config?.host,
          username: res.data.email_config?.username,
          password: res.data.email_config?.password,
          default_from: res.data.email_config?.default_from
        })
        this.logoPreview = logo;
      }
    }, (err) => {
      this._alertService.errorAlert(err ? err : 'Something went wrong, please check your internet connection.');
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  /** Submit Email Configuration */
  public submit() {
    if (this.form.valid) {
      this.form.disable();
      if (this.logoImage) {
        this.uploadImage(this.logoImage).then((logo) => {
          this.form.get('logo').setValue(logo.src);
          this.updateEmailConfig();
        }).catch((err) => {
          this.form.enable();
          this._alertService.errorAlert(err ? err : 'Something went wrong, please check your internet connection.');
        });
      } else {
        this.updateEmailConfig();
      }
    }
  }

  /* Update Email config */
  private updateEmailConfig(){
    this._organizationService.sendEmailConfiguration(this.organizationId, this.form.value).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.form.enable();
      this.emailStatus == 'add' ? this._alertService.successAlert('Email Configuration Added Successfully.', 'Success') : this._alertService.successAlert('Email Configuration Updated Successfully.', 'Success');
      this.emailStatus = 'update';
      this.closeModal.emit();
    }, (err) => {
      this.form.enable();
      this._alertService.errorAlert(err ? err : 'Something went wrong, please check your internet connection.');
    })
  }

  /** Logo For Email Configuration */
  onSelectLogo(file: File) {
    this.logoPreview = null;
    this.logoImage = file ? file : undefined;
    this.form.get('logo').setValue(file ? file.name : null);
  }

  /* Upload Image */
  private uploadImage(file): Promise<any> {
    return this._collectionService.uploadImage(file, this.organizationId).toPromise();
  }
}
