<mat-form-field appearance="fill" class="w-full">
    <mat-label>Search Organization</mat-label>
    <input matInput [matAutocomplete]="auto" [formControl]="_organizationService.searchOrganization" placeholder="Search Organization" (click)="onClick()">
    <button *ngIf="_organizationService.organization_name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()">
        <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected()">
        <mat-option class="autocomplete-option" *ngFor="let organization of filteredOrganization" [value]="organization?._id">
            {{organization?.company_name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>