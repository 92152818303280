import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { bytesToMegabytes } from 'app/functions/bytes-to-megabytes';
import { AlertService } from 'app/services/alert.service';
@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent {

  /* Image Preview URL */
  @Input('image_preview') image_preview: string | ArrayBuffer = undefined;

  /* Image size information */
  @Input('format') format: string = undefined;
  @Input('closeBtn') closeBtn: boolean = true;

  @ViewChild('file') file: ElementRef<HTMLInputElement>;
  @Input('filesize') filesize: number;

  constructor(
    private _alertService: AlertService
  ){}

  @Input('label') label: string = '';
  @Output('onSelect') onSelect: EventEmitter<File> = new EventEmitter();

  /* Select File */
  public filesSelected(file: File){
    if(bytesToMegabytes(file.size) > this.filesize){
      this._alertService.errorAlert('Image size should be less than or equal to '+this.filesize+'MB');
      this.onSelect.emit(null);
      this.image_preview = undefined;
      this.file.nativeElement.value = null;
    } else {
      setTimeout(() => {
        let reader = new FileReader();
        reader.onload = () => {
          this.image_preview = reader.result;
        };
        reader.readAsDataURL(file);
        this.onSelect.emit(file);
      }, 150);
    }
  }

  /* Remove Image */
  public removeImage(){
    this.onSelect.emit(null);
    this.image_preview = undefined;
    this.file.nativeElement.value = null;
  }

}
